<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Feed</strong>
            </div>
            <div class="text-muted">Create new publisher feed</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher of the feed.</span>
                  </template>
                  <a-select
                    v-decorator="['XMLpublisher', { initialValue: initialValue,rules: [{ required: true, message: 'Please select publisher' }]}]"
                    placeholder="Please select a publisher"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="pub in XMLpublisher.list.filter(ele => ele.Status === 0 )" :value="pub.Id"
                                     :key="pub.Id">[{{pub.Id}}] {{ pub.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher feed's name.</span>
                  </template>
                  <a-input type="text" placeholder="Feed Name" v-decorator="[ 'name', {
                  rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher feed's description.</span>
                  </template>
                  <a-input placeholder="Feed Description" v-decorator="['description', {
                   rules: [
                      { max: 512, message: 'Maximum 512 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Fallback URL">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>URL where filtered impressions will go.</span>
                  </template>
                  <a-input type="url" placeholder="Feed Fallback URL" v-decorator="['fallbackUrl', {
                  rules: [
                      { max: 256, message: 'Maximum 256 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impression Tracking Method">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Determines how impressions are tracked.</span>
                  </template>
                  <a-select
                    v-decorator="['tracking']"
                    placeholder="Impression Tracking Method"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option key="0">Impression Pixel</a-select-option>
                    <a-select-option key="1">Any Available Image</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Min. CPC">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Ad with bid below this value will not be shown to a publisher.</span>
                  </template>
                  <a-input type="number" min="0" step="0.01" placeholder="Minimum Bid Amount"
                           v-decorator="[ 'MinCPC', { initialValue: 0, rules: [{validator: bidValidator}] }]">
                    <a-tooltip slot="addonBefore" trigger="click">
                      <a-icon type="dollar"/>
                    </a-tooltip>
                  </a-input>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. CPC">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Ad with bid above this value will not be shown to a publisher.</span>
                  </template>
                  <a-input type="number" min="0" step="0.01" placeholder="Maximum CPC"
                           v-decorator="[ 'MaxCPC', { initialValue: 0.1, rules: [{validator: bidValidator}] }]">
                    <a-tooltip slot="addonBefore" trigger="click">
                      <a-icon type="dollar"/>
                    </a-tooltip>
                  </a-input>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sub ID Mode">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Block or allow publisher sub IDs.</span>
                  </template>
                  <a-select
                    v-decorator="['SubidListMode', { initialValue: 'Blacklist'}]"
                    placeholder="Subid List Mode"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option key="0">Blacklist</a-select-option>
                    <a-select-option key="1">Whitelist</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sub ID List">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>List of sub IDs to block or allow.</span>
                  </template>
                  <a-select
                    v-decorator="['SubidList']"
                    placeholder="Subid List"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                    mode="tags"
                    :tokenSeparators="[' ']"
                  >
                  </a-select>
                </a-tooltip>
              </a-form-item>

              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>List of domains or brand names to block.</span>
                  </template>
                  <a-select
                    v-decorator="['AdDomainsBrandsBlacklist']"
                    placeholder="Ad Domains/Brands Blacklist"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                    mode="tags"
                  >
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of requests allowed per day. The system will even out distribution of requests throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Requests Daily Limit"
                           v-decorator="[ 'RequestsDailyLimit', { initialValue: 0 }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                           v-decorator="[ 'ClicksDailyLimit', { initialValue: 0 }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keyword Blacklists Type">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Type of Keywords blacklists filtering.</span>
                  </template>
                  <a-select
                    v-decorator="['KeywordBlacklistsType', {initialValue: 'Exact'}]"
                    placeholder="Keyword Blacklists Type"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="keywordListType in keywordListsType" :key="keywordListType.Id">
                      {{ keywordListType.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keyword Blacklists">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Keyword lists to set to the publisher feed as blacklists.</span>
                  </template>
                  <a-select
                    v-decorator="['KeywordBlacklists', {initialValue: []}]"
                    placeholder="Keyword Blacklists"
                    :showSearch="true"
                    :filterOption="true"
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="keywordList in activeKwlist" :key="keywordList.Id">{{ keywordList.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Feed</button>
                  <button type="button" @click="$router.push({ name: 'xml-feeds' })" class="btn btn-light px-5 ml-2">
                    Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import { mapState } from 'vuex'
import { getKeywordLists } from '@/api/xml/kwlist'

export default {
  components: {},
  computed: {
    ...mapState(['XMLpublisher']),
    // initialValue () {
    //   if (this.$route.params.publisherId) {
    //     return this.XMLpublisher.list.filter(ele => ele.Id === parseInt(this.$route.params.publisherId))[0].Id
    //   } else { return 'Please select publisher' }
    // },
    initialValue() {
      if (this.$route.params.publisherId) {
        const publisher = this.XMLpublisher.list.find(ele => ele.Id === parseInt(this.$route.params.publisherId))
        return publisher === undefined ? [] : publisher.Id
      } else {
        return []
      }
    },
    activeKwlist() {
      return this.$store.getters['kwlist/getActiveKwLists']
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      keywordLists: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },

    }
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.fallbackUrl !== '' && values.fallbackUrl) {
            values.fallbackUrl = values.fallbackUrl.replace(/\s/g, '')
          }
          // TODO: Maybe validate AdDomainsBrandsBlacklist
          this.$store.dispatch('pubfeed/CREATE_PUBFEED', {
            payload: values,
            callback: function () {
              this.$router.push({ name: 'xml-feeds' })
            }.bind(this),
          })
        }
      })
    },
    bidValidator(rule, value, message) {
      const fields = this.form.getForm()
      const minCPC = parseFloat(fields.getFieldValue('MinCPC'))
      const maxCPC = parseFloat(fields.getFieldValue('MaxCPC'))

      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message((rule.field === 'MinCPC' ? 'Min. CPC' : 'Max. CPC') + ' can not be negative.')
      } else if (rule.field === 'MinCPC' && !isNaN(maxCPC) && minCPC > maxCPC) {
        message('Min. CPC can not be greater than Max. CPC.')
      } else if (rule.field === 'MaxCPC' && !isNaN(minCPC) && minCPC > maxCPC) {
        message('Max. CPC can not be less than Min. CPC.')
      }
      message()
    },
  },
  created() {
    getKeywordLists().then(response => {
      this.keywordLists = response
    })
    this.$store.dispatch('kwlist/LOAD_KWLISTS')
    this.$store.dispatch('XMLpublisher/LOAD_PUBLISHERS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
